


























































import { Component, Vue } from 'vue-property-decorator'
import AonButton from '@/components/AonButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import FluidCheckbox from '@/components/facts/FluidCheckbox.vue'
import constants from '@/constants'
import BackButton from '@/components/BackButton.vue'
import { Auth } from '@/services/Auth'
import { saleJourneyActionHandler, saleJourneyRouteGuard } from '@/services/SaleJourneyService'
import { ACTION_RESET_SALE_JOURNEY_STATE } from '@/store/modules/product/actionTypes'
import { Getter } from 'vuex-class'

@Component({
  components: {
    FluidCheckbox,
    AonButton,
    NextButton,
    BackButton
  }
})
export default class Terms extends Vue {
  @Getter('app/newTermUse') newTermUse!: string

  private accepted = false

  get validated () {
    return this.accepted
  }

  openTermOfUse() {
    this.$modal.show(this.newTermUse ? constants.modalNames.NEW_TERM_OF_USE_MODAL : constants.modalNames.TERM_OF_USE_MODAL)
  }

  openAccountPolicy() {
    this.$modal.show(constants.modalNames.ACCOUNT_POLICY_MODAL)
  }

  openNotices() {
    this.$modal.show(constants.modalNames.NOTICES_MODAL)
  }

  getBackRoute() {
    return Auth.loggedInAs('otp') ? constants.routeNames.DASHBOARD : constants.routeNames.HOME
  }

  getNextRoute() {
    this.$store.dispatch(ACTION_RESET_SALE_JOURNEY_STATE)
    return Auth.loggedInAs('otp') ? constants.routeNames.APPLICATION_DEVICE : constants.routeNames.APPLICATION_EMAIL
  }

  mounted() {
    // Sale Journey Route Guard - to handle back button state
    saleJourneyRouteGuard(saleJourneyActionHandler(this.$store.state.product.saleJourneyState), this.$router, this.$store.state.product.invoiceId)
  }
}
